body {
  margin: 0;
	background: url(../images/bg.jpg) no-repeat center center fixed;
	background-color: #333;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
	color: tomato;
	text-decoration: none;
	font-weight: bold;
}

a:hover {
	color: tan;
}

a:visited {
	color: thistle;
}
